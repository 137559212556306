<template>
  <div class="p-grid">
    <div style="width:20rem; margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff">
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;padding-bottom: 1.5rem;">
        <h3 class="p-m-0" style="line-height: .9">
          <Button v-if="!isEditable"
                  v-tooltip.bottom="_t('label_add_new')"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-plus" style="float: right;"
                  @click="initNewAlarmPointDialog()"
          />
          <strong>{{ _t('label_alarm_templates') }}</strong>
        </h3>
        <br>
        <MultiSelectableBreadcrumb ref="breadcrumbData" :tree-params="treeParams"
                                   single-selection></MultiSelectableBreadcrumb>
      </div>

      <Tree ref="tree"
            v-model:selectionKeys="selectedAlarmPointItem"
            :filter="true"
            :lazy="true"
            :value="alarmPointsGrp"
            class="tight"
            dynamic="true"
            filterBy="label"
            filterMode="strict"
            scrollHeight="80vh"
            selectionMode="single"
            style="min-height: 80vh"
            @node-select="selectAlarmPoint"
      >
        <template #default="slotProps">
          <span v-tooltip.bottom="slotProps.node.label" class="wrap">{{ slotProps.node.label }}</span>
        </template>
      </Tree>
    </div>

    <transition name="slide-fade">
      <Card v-show="selectedAlarmPoint.id != null" class="p-col" style="/*overflow-x: scroll;*/">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0">
                <i v-if="selectedAlarmPoint && selectedAlarmPoint.icon"
                   :class="`icon_medium p-mr-2 ${selectedAlarmPoint.icon.name}`"
                   :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color};`"/>
                {{ _t('label_alarm') }} <strong>{{ selectedAlarmPoint.name }}</strong>
              </h3>
            </div>
            <div>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="isEditable = false; getAlarmPoint(selectedAlarmPoint.id)"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check"
                      @click="saveAlarm()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('label_Edit_alarmtemplate')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil"
                      @click="isEditable = !isEditable"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      @click="deleteAlarmPointDialog = true"/>
            </div>
          </div>
        </template>
        <template #content>
          <TabView v-model:activeIndex="activeTab">
            <TabPanel :header="_t('menu.general')">
              <div class="p-grid">
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_Alarm_name') }}<sup>*</sup></p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <div v-if="isEditable">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                      <InputText v-model="selectedAlarmPoint.name"
                                 :placeholder="_t('label_Alarm_name')"
                                 class="p-m-0" style="width:100%;"
                      />
                    </div>
                    <small v-if="validation.name" class="p-error">{{ _t('label.name_required') }}</small>
                  </div>
                  <p v-else><strong>{{ selectedAlarmPoint.name }}</strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_message_priority') }}<sup>*</sup></p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <Dropdown v-if="isEditable"
                            v-model="selectedAlarmPoint.confirmPriority"
                            :options="[1,2,3,4,5,6,7,8,9,10]"
                            class="p-m-0"
                            style="width: 100%;"/>
                  <p v-else><strong>{{ selectedAlarmPoint.confirmPriority }}</strong></p>
                </div>
                <div class="p-col-1 stripedBg p-text-right" style="vertical-align: middle;">
                  <Checkbox v-model="selectedAlarmPoint.active"
                            :binary="true"
                            :disabled="!isEditable"
                            class="p-m-0"/>
                </div>
                <div class="p-col-6 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_active_alarm') }}</p>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_alarm_text') }}<sup>*</sup></p>

                  <div v-if="isEditable" class="p-inputgroup p-mt-5">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-envelope"></i>
                    </span>
                    <Dropdown v-model="selectedTextVar"
                              :disabled="!isEditable"
                              :options="textVars"
                              :placeholder="_t('select_predefined_variable')"
                              class="p-m-0"
                              style="width: 100%;"
                              @change="copyTextVar"
                    />
                  </div>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditable"
                            v-model="selectedAlarmPoint.text"
                            :placeholder="_t('label_alarm_text')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                  <small v-if="validation.text" class="p-error">{{ _t('alarm_text_required') }}</small>
                  <p v-else><strong>{{ selectedAlarmPoint.text }}</strong></p>
                </div>
                <div class="p-col-12">
                  <h3 class="subhead p-mt-4">&nbsp;</h3>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_description') }}</p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditable"
                            v-model="selectedAlarmPoint.description"
                            :placeholder="_t('label_description')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                  <p v-else><strong>{{ selectedAlarmPoint.description ?? '...' }}</strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <OrganizationLink ref="orgLink" v-model="selectedAlarmPoint" :editMode="isEditable"
                                    modelType="ALARMPOINT"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel :header="_t('PARTICIPANTS')">
              <div class="p-grid">
                <div class="p-col-3" style="vertical-align: middle;">
                  <div v-if="isEditable">
                    <div v-for="(option, i) in srcOptions" :key="option" class="p-field-radiobutton">
                      <RadioButton :id="'srcOption'+i"
                                   v-model="srcOption"
                                   :disabled="!isEditable"
                                   :value="option.value"
                                   name="srcOption"
                                   @change="syncSrcOptions"
                      />
                      <label :for="'srcOption'+i">{{ option.label }}</label>
                    </div>
                  </div>
                  <p v-else>
                    <SelectedOption v-model="srcOption" :options="srcOptions" optionLabel="label" optionValue="value"/>
                  </p>
                </div>
                <div v-if="srcOption=='EXIST'" class="p-col-9" style="vertical-align: middle;">
                  <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-exclamation-triangle"></i>
                    </span>
                    <Dropdown v-model="selectedAlarmPoint.alarmPlanId"
                              :filter="true"
                              :filterPlaceholder="_t('label_Select_plan')"
                              :options="alarmPlans"
                              class="p-m-0"
                              optionLabel="name"
                              optionValue="id"
                              style="width:100%;"
                              @change="getAlarmPlan($event.value)"
                    />
                    <span class="p-inputgroup-addon">
                      <i v-tooltip.bottom="_t('Clear')"
                         class="pi pi-times"
                         @click="selectedAlarmPlan = {id: null}; selectedAlarmPoint.alarmPlanId = null"></i>
                    </span>
                  </div>
                  <p v-else><strong>
                    <SelectedOption v-model="selectedAlarmPlan.id" :options="alarmPlans" optionLabel="name"
                                    optionValue="id"/>
                  </strong></p>
                </div>
                <div v-if="srcOption=='NEW'" class="p-col-9" style="vertical-align: middle;">
                  <div class="p-grid">
                    <div class="p-col-4 stripedBg">
                      <p>{{ _t('label_Participant_plan_name') }}<sup>*</sup></p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-pencil"></i>
                        </span>
                        <InputText v-model="selectedAlarmPlan.name" :placeholder="_t('label_Participant_plan_name')"
                                   class="p-m-0"
                                   style="width:100%;"/>
                      </div>
                    </div>
                    <div class="p-col-4">
                      <p>{{ _t('label_Participant_plan_description') }}</p>
                    </div>
                    <div class="p-col-8">
                      <div class="p-inputgroup p-mt-2">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi pi-pencil"></i>
                        </span>
                        <InputText v-model="selectedAlarmPlan.description"
                                   :placeholder="_t('label_Participant_plan_description')"
                                   class="p-m-0" style="width:100%;"/>
                      </div>
                    </div>
                    <div class="p-col-4 stripedBg">
                      <p>{{ _t('label_organization') }}</p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <OrganizationLink v-model="selectedAlarmPlan" :editMode="isEditable"/>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="subhead">{{ _t('label_Target_groups') }}</h3>
              <TabView v-model:activeIndex="activeTabSimple">
                <TabPanel :header="_t('label_Standard_view')">
                  <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                       class="p-grid">
                    <Card
                        v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                        :key="callGroup"
                        class="p-mr-2 p-mb-2"
                        style="display:inline-block; width:20rem; font-size: 12px;"
                    >
                      <template #content>
                        <div class="p-grid">
                          <div class="p-col-9">
                            <div v-if="isEditable && srcOption != 'EXIST'" class="p-inputgroup">
                                  <span class="p-inputgroup-addon">
                                    <i class="pi pi-pencil"></i>
                                  </span>
                              <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                         :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                            </div>
                            <p v-else class="color-green">
                              <strong>
                                {{
                                  selectedAlarmPlan.callGroups[index].name && selectedAlarmPlan.callGroups[index].name.length ?
                                      selectedAlarmPlan.callGroups[index].name :
                                      '[...]'
                                }}
                              </strong>
                            </p>
                          </div>
                          <div class="p-col-3 p-text-right">
                            <Button v-if="isEditable && srcOption != 'EXIST'" class="p-button-danger"
                                    @click="initCallGroupDeleteDialog(index)">
                              <i class="pi pi-trash"></i>
                            </Button>
                          </div>
                          <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                            {{ _t('label_notification_method') }}
                          </div>
                          <div
                              :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                            <Dropdown v-if="isEditable && srcOption != 'EXIST'"
                                      v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                      :options="callRules"
                                      class="p-m-0"
                                      optionLabel="label"
                                      optionValue="value"
                                      style="width:100%;"
                                      @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                            />
                            <strong v-else>
                              <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                              :options="callRules"
                                              default="..."
                                              optionLabel="label"
                                              optionValue="value"/>
                            </strong>
                          </div>
                          <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                            <span>{{ _t('label_Number_required_ack') }}</span>
                          </div>
                          <div
                              :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                            <InputText v-if="isEditable && srcOption != 'EXIST'"
                                       v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                       class="p-m-0" min="0"
                                       style="width: 100%;" type="number"
                            />
                            <strong v-else>{{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong>
                          </div>
                          <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                            {{ _t('label_Method_participants_selection') }}
                          </div>
                          <div
                              :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                            <Dropdown v-if="isEditable && srcOption != 'EXIST'"
                                      v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                      :disabled="!selectedAlarmPlan.callGroups[index]"
                                      :options="selectRules"
                                      class="p-m-0"
                                      optionLabel="label"
                                      optionValue="value"
                                      style="width:100%;"
                                      @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                            />
                            <strong v-else>
                              <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                              :options="selectRules" default="..."
                                              optionLabel="label"
                                              optionValue="value"/>
                            </strong>
                          </div>
                          <div class="p-col-8" style="vertical-align: middle;">
                            <h3 class="subhead">{{ _t('label_participants') }}</h3>
                          </div>
                          <div class="p-col-4 p-text-right">
                                <span v-if="isEditable && srcOption != 'EXIST'" class="p-buttonset">
                                  <Button v-tooltip.bottom="_t('Update')"
                                          class="p-button-success p-button-icon-only p-button-text"
                                          @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                                    <i class="pi pi-plus-circle"></i>
                                  </Button>
                                  <Button
                                      v-tooltip.bottom="_t('Delete')"
                                      :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                                      class="p-button-danger p-button-icon-only p-button-text"
                                      @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                                    <i class="pi pi-minus-circle"></i>
                                  </Button>
                                </span>
                          </div>
                          <div class="p-col-12">
                            <DataTable
                                v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                                :disabled="!isEditable || srcOption == 'EXIST'" :scrollable="true"
                                :value="selectedAlarmPlan.callGroups[index].members"
                                class="p-m-0 condensed"
                                dataKey="id"
                                scrollHeight="20rem"
                                selectionMode="multiple"
                                style="width:100%; font-size: 12px;"
                                @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                                @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"
                            >
                              <Column :rowReorder="isEditable && srcOption != 'EXIST'" style="flex: 0 0 2rem"/>
                              <Column field="title"
                              >
                                <template #body="slotProps">
                                  <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                  <i v-else class="pi pi-users p-mr-1"></i>
                                  {{ slotProps.data.title }}
                                </template>
                              </Column>
                              <Column :selectionMode="(isEditable && srcOption != 'EXIST') ? 'multiple' : ''"
                                      style="flex: 0 0 2rem"/>
                            </DataTable>
                          </div>
                        </div>
                      </template>
                    </Card>
                    <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                         style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus" @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{ _t('label_No_target_groups') }}</p>
                    <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                         style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus" @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel :header="_t('label_Simplified_view')">
                  <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                       class="p-grid">
                    <Card
                        v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                        :key="callGroup.id"
                        class="p-mr-2 p-mb-2"
                        style="display:inline-block; width:20rem; font-size: 12px;"
                    >
                      <template #content>
                        <div class="p-grid">
                          <div class="p-col-9">
                            <div v-if="isEditable && srcOption != 'EXIST'" class="p-inputgroup">
                                  <span class="p-inputgroup-addon">
                                    <i class="pi pi-pencil"></i>
                                  </span>
                              <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                         :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                            </div>
                            <p v-else class="color-green">
                              <strong>{{
                                  selectedAlarmPlan.callGroups[index].name && selectedAlarmPlan.callGroups[index].name.length ?
                                      selectedAlarmPlan.callGroups[index].name :
                                      '[...]'
                                }}
                              </strong>
                            </p>
                          </div>
                          <div class="p-col-3 p-text-right">
                            <Button v-if="isEditable && srcOption != 'EXIST'"
                                    class="p-button-danger"
                                    @click="initCallGroupDeleteDialog(index)">
                              <i class="pi pi-trash"></i>
                            </Button>
                          </div>
                          <div v-if="isEditable && srcOption != 'EXIST'" class="p-col-12">
                            <p><strong>{{ _t('label_select_notification_method') }}</strong></p>
                            <div v-for="(rule, i) in callRulesSimple"
                                 :key="rule.value"
                                 :class="['p-field-radiobutton', (rule.value == 'OTHER' ? 'hidden' : '')]">
                              <RadioButton :id="'call_rule'+i"
                                           v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                           :disabled="!isEditable"
                                           :value="rule.value"
                                           name="callRule"
                                           @change="syncGroupSelectRuleSimple(selectedAlarmPlan.callGroups[index])"/>
                              <label :for="'call_rule'+i">{{ rule.label }}</label>
                            </div>
                          </div>
                          <div v-else class="p-col-12">
                            <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                            :options="callRulesSimple" default="..."
                                            optionLabel="label"
                                            optionValue="value"/>
                          </div>
                        </div>
                      </template>
                    </Card>
                    <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                         style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus" @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{ _t('label_No_target_groups') }}</p>
                    <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                         style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus" @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </TabPanel>
            <TabPanel :header="_t('label_instruction_files')">
              <div v-if="selectedAlarmPoint.instructionFiles && selectedAlarmPoint.instructionFiles.length > 0"
                   class="p-grid p-nogutter p-mb-4">
                <div v-for="(instruction, index) in selectedAlarmPoint.instructionFiles"
                     :key="index"
                     class="p-col-12"
                >
                  <Button :label="instruction.split('/').pop()"
                          class="p-button-link p-button-text p-button-success"
                          @click="downloadInstruction(instruction)"
                  />
                  <Button v-if="isEditable"
                          class="p-button-rounded p-button-danger p-button-text"
                          icon="pi pi-times"
                          @click="deleteIntruction(instruction)"
                  />
                </div>
              </div>
              <p v-else><b>{{ _t('label_intruction_file_missed') }}</b></p>
              <div v-if="isEditable" class="p-grid">
                <div class="p-col-3 stripedBg">
                  <p>
                    {{ _t('select_instruction_file') }}
                  </p>
                </div>
                <div class="p-col-5 stripedBg">
                  <Dropdown v-if="isEditable"
                            v-model="selectedAlarmPoint.instructionFile"
                            :options="instructions"
                            class="p-m-0"
                            optionLabel="name"
                            optionValue="path"
                            style="width: 100%;"
                  >
                    <template #option="slotProps">
                      <div class="p-dropdown-option">
                        <span v-if="slotProps.option.org.id != 1">
                          {{ slotProps.option.org.fullOrgPath.replace('[Total system]-', '') }} -
                        </span> {{ slotProps.option.name }}
                      </div>
                    </template>
                  </Dropdown>
                </div>
                <div class="p-col-4 stripedBg">
                  <Button v-if="isEditable"
                          :disabled="!selectedAlarmPoint.instructionFile"
                          :label="_t('label_assign')"
                          class="p-button-success"
                          @click="addInstruction"
                  />
                  <span style="float: right"><UploadFile @uploaded="loadInstructions"/></span>
                </div>
              </div>
            </TabPanel>
            <TabPanel :header="_t('label_advanced')">
              <div class="p-grid">
                <div class="p-col-12">
                  <h3 class="subhead">{{ _t('label_more_notifications_settings') }}</h3>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_confirm_mode') }}<sup>*</sup></p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <Dropdown v-if="isEditable"
                            v-model="selectedAlarmPoint.acknowledgeMode"
                            :options="confirmationModes"
                            class="p-m-0"
                            optionLabel="label"
                            optionValue="value"
                            style="width: 100%;"/>
                  <p v-else><strong>
                    <SelectedOption v-model="selectedAlarmPoint.acknowledgeMode"
                                    :options="confirmationModes"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p :class="!isEditable || selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                    {{ _t('label_confirm_time') }}<sup>*</sup></p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <InputText v-if="isEditable"
                             v-model="selectedAlarmPoint.localAcknowledgeTime"
                             :disabled="selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA'"
                             class="p-m-0"
                             min="0"
                             style="width:100%;"
                             type="number"
                  />
                  <p v-else
                     :class="selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                    <strong>{{ selectedAlarmPoint.localAcknowledgeTime }}</strong>
                  </p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <p :class="!isEditable || selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                    {{ _t('label_minutes') }}</p>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_media_usage_manual') }}<sup>*</sup></p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Dropdown v-if="isEditable"
                            v-model="selectedAlarmPoint.mediaUsageType"
                            :options="mediaUsageTypes"
                            class="p-m-0"
                            optionLabel="label"
                            optionValue="value"
                            style="width: 100%;"/>
                  <p v-else><strong>
                    <SelectedOption v-model="selectedAlarmPoint.mediaUsageType"
                                    :options="mediaUsageTypes"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_media_usage') }}<sup>*</sup></p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <Dropdown v-if="isEditable"
                            v-model="selectedAlarmPoint.mediaProcessingType"
                            :options="mediaProcessingTypes"
                            class="p-m-0"
                            optionLabel="label"
                            optionValue="value"
                            style="width: 100%;"/>
                  <p v-else><strong>
                    <SelectedOption v-model="selectedAlarmPoint.mediaProcessingType"
                                    :options="mediaProcessingTypes"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong></p>
                </div>
                <div class="p-col-12">
                  <h3 class="subhead p-mt-4">{{ _t('label_more_alarm_settings') }}</h3>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_speech_text') }}</p>
                  <Button v-if="isEditable" :label="_t('label_copy_from_alarmtext')"
                          class="p-button p-button-secondary p-mt-4"
                          @click="selectedAlarmPoint.speechText = selectedAlarmPoint.text"/>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditable"
                            v-model="selectedAlarmPoint.speechText"
                            :placeholder="_t('label_speech_text')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                  <p v-else><strong>{{ selectedAlarmPoint.speechText }}</strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_rep_mon_interval') }}</p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <Checkbox v-model="repeatableMonitored"
                            :binary="true"
                            :disabled="!isEditable"
                            class="p-m-0"
                            @click="syncRepeatableMonitored"
                  />
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <InputText v-if="isEditable"
                             v-model="selectedAlarmPoint.repeatMonInterval"
                             :disabled="!repeatableMonitored"
                             :min="repeatableMonitored ? 1 : 0"
                             class="p-m-0"
                             style="width:100%"
                             type="number"/>
                  <p v-else><strong>{{ selectedAlarmPoint.repeatMonInterval }}</strong></p>
                </div>
                <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_minutes') }}</p>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_Trigger') }}<sup>*</sup></p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Dropdown v-if="isEditable"
                            v-model="selectedAlarmPoint.triggering"
                            :options="triggerings"
                            class="p-m-0"
                            optionLabel="label"
                            optionValue="value"
                            style="width: 100%;"/>
                  <p v-else><strong>
                    <SelectedOption v-model="selectedAlarmPoint.triggering"
                                    :options="triggerings"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_number_of_repeats') }}<sup>*</sup></p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <InputText v-if="isEditable"
                             v-model="selectedAlarmPoint.countRepeat"
                             class="p-m-0"
                             min="0"
                             style="width:100%"
                             type="number"/>
                  <p v-else><strong>{{ selectedAlarmPoint.countRepeat }}</strong></p>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_between_repeats') }}<sup>*</sup></p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <InputText v-if="isEditable"
                             v-model="selectedAlarmPoint.repeatBetween"
                             class="p-m-0"
                             min="0"
                             style="width:100%"
                             type="number"/>
                  <p v-else><strong>{{ selectedAlarmPoint.repeatBetween }}</strong></p>
                </div>
                <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_minutes') }}</p>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_alarm_event_rule') }}</p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <Dropdown
                      v-if="isEditable"
                      v-model="selectedAlarmPoint.alarmRuleId"
                      :disabled="!isEditable"
                      :options="alarmRules"
                      :placeholder="_t('label_alarm_event_rule')"
                      class="p-m-0"
                      option-label="name"
                      option-value="id"
                      show-clear
                      style="width: 100%;"
                  />
                  <p v-else><strong>{{
                      alarmRules.find(i => i.id === selectedAlarmPoint.alarmRuleId)?.name ?? ''
                    }}</strong></p>
                </div>
              </div>
            </TabPanel>
            <TabPanel :header="_t('label_Icon')">
              <div v-if="isEditable" class="p-grid">
                <div class="p-col-12">
                  <h3 class="subhead">{{ _t('label_Colour') }}</h3>
                </div>
                <div v-for="(color, i) in iconColors" :key="color" class="p-col-1 p-text-center">
                  <label :for="`color${i}`">
                    <div :style="`background-color: ${color};`" class="color_sample p-mb-2"/>
                  </label>
                  <RadioButton v-if="selectedAlarmPoint && selectedAlarmPoint.icon" :id="`color${i}`"
                               v-model="selectedAlarmPoint.icon.color"
                               :disabled="!isEditable"
                               :value="color" name="color"/>
                </div>
                <div class="p-col-12">
                  <h3 class="subhead">{{ _t('label_Icon') }}</h3>
                </div>
                <Card v-for="(section, s) in icons" :key="section.label" class="p-col-3">
                  <template #title>
                    <p>{{ section.label }}</p>
                  </template>
                  <template #content>
                    <div class="p-grid">
                      <div v-for="(icon, i) in section.icons" :key="icon" class="p-col-3 p-text-center">
                        <label :for="`icon_${s}_${i}`">
                          <i :class="`icon p-mb-2 ${icon}`"/>
                        </label><br>
                        <RadioButton v-if="selectedAlarmPoint && selectedAlarmPoint.icon" :id="`icon_${s}_${i}`"
                                     v-model="selectedAlarmPoint.icon.name" :disabled="!isEditable"
                                     :value="icon" name="icon"/>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
              <div v-else>
                <i v-if="selectedAlarmPoint && selectedAlarmPoint.icon"
                   :class="`icon_big p-mb-2 ${selectedAlarmPoint.icon.name}`"
                   :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color};`"/>
              </div>
            </TabPanel>
            <TabPanel :header="_t('label_calendar')">
              <div class="p-grid">
                <div class="p-col-12 stripedBg p-py-2" style="vertical-align: middle;">
                  <div><i class="pi pi-calendar"></i> <strong>{{ _t('label_regular_calendar') }}</strong>
                    <Button
                        v-if="isEditable && getCalendar"
                        :label="_t('label_unassign_schedule')"
                        class="p-button-danger"
                        style="float: right"
                        @click="onUnAssignRotationalCalendar"
                    />
                  </div>
                </div>
              </div>
              <CalendarConfigurator v-if="getCalendar" v-model:orgId="orgId"></CalendarConfigurator>
              <template v-else-if="isEditable">
                <div class="p-grid">
                  <div class="p-col-3 p-mt-2">
                    {{ _t('label_assign_existing_schedule') }}
                  </div>
                  <div class="p-col p-mt-2">
                    <Dropdown v-model="rotationalCalendar" :options="getRotationalCalendars"
                              :placeholder="_t('Select')" optionLabel="name"
                              style="min-width: 400px; width: 100%"
                    />
                  </div>
                  <div class="p-col-3 p-mt-2">
                    <Button :disabled="!rotationalCalendar.id" class="p-button-secondary"
                            @click="onAssignRotationalCalendar">{{ _t('label_assign') }}
                    </Button>
                  </div>
                  <div class="p-col-12">
                    <hr>
                  </div>
                  <div class="p-col-3">
                    {{ _t('label_create') }}
                  </div>
                  <div class="p-col p-d-flex">
                    <InputText v-model="rotationalCalendar.name" :placeholder="_t('label_object_name')"
                               class="p-d-inline-flex"
                               style="width: 100%"
                               type="text"/>
                    <InputText v-model="rotationalCalendar.description" :placeholder="_t('label_description')"
                               class="p-d-inline-flex"
                               style="width: 100%" type="text"/>
                    <Calendar v-model="rotationalCalendar.validFrom" class="p-d-inline-flex" dateFormat="dd.mm.yy"
                              style="width: 100%"/>
                  </div>
                  <div class="p-col-3">
                    <Button :disabled="!rotationalCalendar" class="p-button-secondary"
                            @click="onCreateRotationalCalendar">
                      {{ _t('label_create') }}
                    </Button>
                  </div>
                </div>
              </template>
              <template v-else>{{ _t('label_object_name') }}</template>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </transition>
  </div>

  <Dialog v-model:visible="deleteAlarmPointDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{ selectedAlarmPoint.name }}</strong>: {{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteAlarmPointDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteAlarmPoint()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Add_new_target_group') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText v-model="newCallGroup.name" :placeholder="_t('label_group_name')" class="p-m-0" style="width: 100%;"/>
    </div>
    <div class="p-mt-2">
      <p>{{ _t('label_organization') }}
        <OrganizationLink v-model="selectedAlarmPlan" :editMode="false"/>
      </p>
      <p>{{ _t('label_alarm_plan') }} <strong>{{ selectedAlarmPlan.name }}</strong></p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Update_participants') }}</h3>
    </template>
    <p>{{ _t('label_Update_participants_for_target_group') }} </p>
    <ParticipantSelector v-model="editCallGroup.members" style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name && selectedAlarmPlan.callGroups[deleteCallGroupIndex].name.length ? selectedAlarmPlan.callGroups[deleteCallGroupIndex].name : '[No title]'
      }}: </strong>{{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteCallGroup()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newEventDialog" :modal="true" :style="{width: '500px'}">
    <template #header>
      <h3>{{ _t('label_alarm_event') }}</h3>
    </template>
    <span class="p-float-label p-mt-5">
      <InputText id="description" v-model="getEvent.title" style="width: 100%" type="text"/>
      <label for="description">{{ _t('label_description') }}</label>
    </span>
    <span class="p-float-label p-mt-5">
      <AutoComplete
          v-model="getEvent.alarmReplacement"
          :dropdown="true"
          :placeholder="_t('label_object_name')"
          :suggestions="filteredAlarmPoints"
          class="p-p-0"
          field="name"
          style="width: 100%"
          @complete="searchAlarmPoint($event)"
      />
    </span>
    <div class="p-grid p-mt-5">
      <div class="p-col-4">
        <span class="p-float-label">
          <Calendar id="startDateSelected" v-model="getEvent.startDateSelected" :showTime="false"
                    dateFormat="dd.mm.yy"/>
          <label for="startDateSelected">{{ _t('label.Start_date') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="startTime" v-model="getEvent.startTime" :disabled="allDay" :showTime="true" :timeOnly="true"/>
          <label for="startTime">{{ _t('label.Start_time') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="endTime" v-model="getEvent.endTime" :disabled="allDay" :showTime="true" :timeOnly="true"/>
          <label for="endTime">{{ _t('label.End_time') }}</label>
        </span>
      </div>
      <div class="p-col-2 p-pt-2">
        <div class="p-field-checkbox p-pt-1">
          <Checkbox id="allday" v-model="allDay" :binary="true"/>
          <label for="allday">{{ _t('label_all_day') }}</label>
        </div>
      </div>
    </div>
    <span class="p-float-label p-mt-3">
      <Checkbox id="active" v-model="getEvent.active" :binary="true"/>
      <label class="p-ml-4" for="active">{{ _t('label_active_alarm') }}</label>
    </span>
    <span class="p-float-label p-mt-3">
      <Checkbox id="repeatType" v-model="getEvent.recurring" :binary="true"/>
      <label class="p-ml-4" for="repeatType">{{ _t('repeat_type') }}</label>
    </span>
    <div v-if="getEvent.recurring">
      <span class="p-float-label p-mt-3">
        <Dropdown
            v-model="getEvent.repeatType"
            :options="getRepeatTypes"
            class="p-p-0"
            optionValue="value"
            placeholder="Select a type"
            style="width: 100%"
        >
          <template #option="slotProps">
            {{ _t(slotProps.option.value) }}
          </template>
          <template #value="slotProps">
            {{ _t(slotProps.value) }}
          </template>
        </Dropdown>
      </span>
      <span class="p-float-label p-mt-5">
        <InputNumber id="repeatEvery" v-model="getEvent.repeatEvery" :useGrouping="false" class="p-p-0" mode="decimal"
                     style="width: 100%" type="text"/>
        <label for="repeatEvery">{{ _t('label_repeat_every') }} ({{ repeatEveryTitle }})</label>
      </span>
      <span v-if="getEvent.repeatType === repeatTypeEnum.WEEKLY" class="p-d-flex p-mt-4">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }} </label>
        <div v-for="weekDay in weekDayEnum" :key="weekDay" class="p-d-inline">
          <Checkbox :id="weekDay" v-model="getEvent.markedDays" :name="weekDay" :value="weekDay"/>
          <label :for="weekDay" class="p-pl-1 p-pr-3">{{ weekDay.charAt(0).toUpperCase() }}</label>
        </div>
      </span>
      <span v-if="getEvent.repeatType === repeatTypeEnum.MONTHLY" class="p-d-flex p-mt-4">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }}</label>
        <Dropdown
            v-model="getEvent.monthSubType"
            :options="repeatByMonth"
            :placeholder="_t('Select')"
            class="p-p-0"
            optionLabel="label"
            optionValue="value"
            style="width: 100%"
        />
      </span>
      <div class="p-d-flex p-flex-row p-mt-5">
        <div class="p-d-inline-flex p-ai-start p-mt-4 p-pr-4">
          <label class="p-ml-2" for="ends">
            {{ _t('label_ends') }}
          </label>
        </div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4">
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsAfter" v-model="getEvent.endType" value="AFTER_TIMES_END"/>
              <label for="endsAfter">{{ _t('label_after') }}</label>
            </span>
          </div>
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsOn" v-model="getEvent.endType" value="ON_DATE"/>
              <label for="endsOn">{{ _t('label_on') }}</label>
            </span>
          </div>
        </div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4" style="width: 100%">
          <span class="p-d-inline-flex p-pb-2" style="margin-top: -5px">
            <InputNumber id="countAfterTimesEnd" v-model="getEvent.countAfterTimesEnd"
                         :disabled="getEvent.endType !== 'AFTER_TIMES_END'" :useGrouping="false"
                         class="p-p-0" mode="decimal" style="width: 100%"
                         type="text"/>
            <label class="p-pt-2 p-pl-2" for="countAfterTimesEnd">{{ _t('label_times') }}</label>
          </span>
          <span class="p-d-inline-flex">
            <Calendar id="endDateSelected" v-model="getEvent.endDateSelected" :disabled="getEvent.endType !== 'ON_DATE'"
                      :showTime="false"
                      dateFormat="dd.mm.yy"/>
          </span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button v-if="getIsEditable" :label="_t('label_Remove')" class="p-button-text p-button-danger" icon="pi pi-trash"
              style="float: left" @click="removeSelectedEvent()"/>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times"
              @click="setEvent(null); newEventDialog = false"/>
      <Button :label="_t('Save')" autofocus icon="pi pi-check" @click="createNewEvent()"/>
    </template>
  </Dialog>
</template>
<style scoped>
.hidden {
  display: none;
}

.color_sample {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.icon_big {
  font-size: 48px;
  padding: 4px;
  border-radius: 4px;
}

.icon_medium {
  font-size: 24px;
  padding: 4px;
  border-radius: 4px;
}

.icon {
  font-size: 24px;
}

.p-listbox.grp .p-listbox-list .p-listbox-item {
  padding-left: 2em;
}

.p-tree.tight .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: -0.5rem;
  width: 1.2rem;
  height: 1.7rem;
}

.p-tree.tight .p-treenode-label {
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script>
import router from '@/router'
import {getList} from '@/api/list'
import {create, getItemById, prepare, removeItemById, update} from '@/api/alarm-templates'
import {createAlarmPlan, getAlarmPlan, updateAlarmPlan} from '@/api/alarmPlan'
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb'
import {downloadInstruction} from '@/api/instruction'
import SelectedOption from '../../components/ixarma/SelectedOption'
import OrganizationLink from '../../components/ixarma/OrganizationLink'
import ParticipantSelector from '../../components/ixarma/ParticipantSelector'
import CalendarConfigurator from '@/views/standby_schedules/calendar-configurator'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import moment from 'moment-timezone'
import {getInstructionFiles} from "../../api/attachments";
import UploadFile from '@/components/UploadFile'

export default {
  name: 'index',
  components: {
    SelectedOption,
    OrganizationLink,
    MultiSelectableBreadcrumb,
    // ChildFilter,
    CalendarConfigurator,
    ParticipantSelector,
    UploadFile
  },
  data() {
    return {
      alarmPoints: null,
      alarmPointError: [],
      selectedAlarmPointItem: null,
      selectedAlarmPoint: {
        id: null,
        icon: {
          name: '',
          color: ''
        },
        repeatMonInterval: 1
      },
      isEditable: false,
      alarmPointSearch: '',
      deleteAlarmPointDialog: false,
      newAlarmPointDialog: false,
      selectedTextVar: null,
      textVars: [
        '<original>', '<K>', '<G>', '<family>', '<callerid>'
      ],
      selectedAlarmRule: null,
      alarmRules: [],
      confirmationModes: [],
      mediaUsageTypes: [],
      mediaProcessingTypes: [],
      triggerings: [],
      srcOptions: [],
      instructions: [],
      srcOption: null,
      repeatableMonitored: false,
      activeTabSimple: 0,
      selectedAlarmPlan: {
        id: null
      },
      alarmPlans: [],
      selectedCallGroupMembers: {},
      callRules: [],
      callRulesSimple: [],
      selectRules: [],
      participants: [],
      participantGroups: [],
      newCallGroupDialog: false,
      newCallGroup: {},
      editCallGroupDialog: false,
      originalCallGroup: null,
      editCallGroup: {
        participants: [],
        participantGroups: [],
      },
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      participantFilterValue: '',
      treeParams: '',
      orgId: localStorage.getItem('orgId') ?? null,
      iconColors: [
        '#3996f3',
        '#314c5d',
        '#fad165',
        '#85b44a',
        '#8c5cfd',
        '#46c1d3',
        '#e23e57',
        '#989898',
        '#1b499e',
        '#f5856d'
      ],
      featuredIcons: [
        'mdi mdi-alert-outline',
        'mdi mdi-alert-box-outline',
        'mdi mdi-exit-to-app',
        'mdi mdi-fire',
        'mdi mdi-water-alert-outline',
        'mdi mdi-hospital-box-outline',
        'mdi mdi-wrench-outline',
        'mdi mdi-lan-disconnect',
        'mdi mdi-alarm-light-outline',
        'mdi mdi-shield-alert-outline',
        'mdi mdi-account-alert-outline',
        'mdi mdi-lock-open-outline',
        'mdi mdi-help-circle-outline',
        'mdi mdi-information-outline'
      ],
      icons: [],
      alarmPointsGrp: [],
      regularCalendar: {
        events: [{
          "resourceId": "d",
          "title": "event 1",
          "start": "2021-09-23T12:30:00+00:00",
          "end": "2021-09-25T15:30:00+00:00"
        }, {
          "resourceId": "c",
          "title": "event 3",
          "start": "2021-09-24T12:30:00+00:00",
          "end": "2021-09-25T06:45:00+00:00"
        }, {
          "resourceId": "f",
          "title": "event 4",
          "start": "2021-09-24T07:30:00+00:00",
          "end": "2021-09-24T09:30:00+00:00"
        }, {
          "resourceId": "b",
          "title": "event 5",
          "start": "2021-09-24T10:00:00+00:00",
          "end": "2021-09-24T15:00:00+00:00"
        }, {
          "resourceId": "e",
          "title": "event 2",
          "start": "2021-09-24T09:00:00+00:00",
          "end": "2021-09-24T14:00:00+00:00"
        }],
      },
      activeTab: 0,
      rotationalCalendar: {
        id: null,
        name: '',
        description: '',
        validFrom: moment().format('DD.MM.YYYY'),
      },
      newEventDialog: false,
      allDay: false,
      repeatByMonth: [{
        label: 'Day of month',
        value: 'DAY_OF_THE_MONTH',
      }, {
        label: 'Day of week',
        value: 'DAY_OF_THE_WEEK',
      }],
      filteredAlarmPoints: null,
      hoursDuration: 999999999,
      groupFilter: false,
      validation: {
        name: false,
        text: false
      }
    }
  },
  watch: {
    alarmPointSearch() {
      this.getAlarmPoints()
    },
    isEditable() {
      this.setRotationalIsEditable(this.isEditable);
    },
    // repeatableMonitored(value) {
    //   if (value === false || this.selectedAlarmPoint.repeatMonInterval <= 1) {
    //     this.selectedAlarmPoint.repeatMonInterval = value ? 1 : 0
    //   }
    // },
  },
  mounted() {
    this.selectedAlarmPoint = {
      id: null,
      icon: {
        name: '',
        color: ''
      },
      repeatMonInterval: 1

    },
        this.mediaProcessingTypes = [
          {value: 'CONFERENCE_CALL', label: this._t('CONFERENCE_CALL')},
          {value: 'BY_RANK', label: this._t('BY_RANK')}
        ]
    this.triggerings = [
      {value: 'WHEN_ON', label: this._t('WHEN_ON')},
      {value: 'WHEN_ON_AND_OFF', label: this._t('WHEN_ON_AND_OFF')}
    ]
    this.mediaUsageTypes = [
      {value: 'ALL', label: this._t('label_as_configured')},
      {value: 'VOICE', label: this._t('label_voice_only')},
      {value: 'TEXT', label: this._t('label_text_only')}
    ]
    this.confirmationModes = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'MEDIA', label: this._t('_MEDIA')},
      {value: 'PARTICIPANT', label: this._t('_PARTICIPANT')},
      {value: 'MEDIA_AND_PARTICIPANT', label: this._t('_MEDIA_AND_PARTICIPANT')}
    ]
    this.icons = [
      {
        label: this._t('menu.general'),
        icons: [
          'mdi mdi-alert-outline',
          'mdi mdi-bell-ring-outline',
          'mdi mdi-alarm',
          'mdi mdi-alert-rhombus-outline',
          'mdi mdi-bell-alert-outline',
          'mdi mdi-car-brake-alert',
          'mdi mdi-alert-circle-outline',
          'mdi mdi-alert-octagon-outline',
          'mdi mdi-alert-decagram-outline',
          'mdi mdi-alert-box-outline',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-alarm-multiple',
          'mdi mdi-bell-circle-outline',
        ]
      },
      {
        label: this._t('label_Alarms'),
        icons: [
          'mdi mdi-exit-run',
          'mdi mdi-exit-to-app',
          'mdi mdi-fire',
          'mdi mdi-fire-extinguisher',
          'mdi mdi-air-horn',
          'mdi mdi-fire-hydrant',
          'mdi mdi-water-alert-outline',
          'mdi mdi-water-remove-outline',
          'mdi mdi-pipe-leak',
          'mdi mdi-ambulance',
          'mdi mdi-medical-bag',
          'mdi mdi-hospital-box-outline',
          'mdi mdi-zodiac-aquarius',
          'mdi mdi-wrench-outline',
          'mdi mdi-server-off',
          'mdi mdi-lan-disconnect',
          'mdi mdi-alarm-light-outline',
        ]
      },
      {
        label: this._t('label_Danger'),
        icons: [
          'mdi mdi-alarm-light-outline',
          'mdi mdi-hazard-lights',
          'mdi mdi-triforce',
          'mdi mdi-molecule-co2',
          'mdi mdi-flash-alert-outline',
          'mdi mdi-bottle-tonic-skull-outline',
          'mdi mdi-skull-crossbones-outline',
          'mdi mdi-skull',
          'mdi mdi-virus-outline',
          'mdi mdi-bacteria-outline',


        ]
      },
      {
        label: this._t('label_Security'),
        icons: [
          'mdi mdi-shield-alert-outline',
          'mdi mdi-shield-key-outline',
          'mdi mdi-account-alert-outline',
          'mdi mdi-security-network',
          'mdi mdi-security',
          'mdi mdi-lock-open-alert',
          'mdi mdi-lock-open-outline',
          'mdi mdi-key-outline',
          'mdi mdi-key-remove',
          'mdi mdi-home-alert',
          'mdi mdi-home-lock-open',
          'mdi mdi-window-shutter-alert',
          'mdi mdi-volume-off',
        ]
      },
      {
        label: this._t('label_Maintenance'),
        icons: [
          'mdi mdi-broom',
          'mdi mdi-rake',
          'mdi mdi-hammer-wrench',
          'mdi mdi-wrench-outline',
        ]
      },
      {
        label: this._t('label_IT'),
        icons: [
          'mdi mdi-help-circle-outline',
          'mdi mdi-lan-disconnect',
          'mdi mdi-server-off',
          'mdi mdi-desktop-classic',
          'mdi mdi-content-save-alert-outline',
          'mdi mdi-disc-alert',
          'mdi mdi-timeline-alert-outline',
          'mdi mdi-folder-key-outline',
          'mdi mdi-folder-alert',
          'mdi mdi-table-alert',
          'mdi mdi-wifi-strength-alert-outline',
          'mdi mdi-restart-alert',

        ]
      },
      {
        label: this._t('label_Info_Calendar'),
        icons: [
          'mdi mdi-information-outline',
          'mdi mdi-information-variant',
          'mdi mdi-clock-alert-outline',
          'mdi mdi-calendar-alert',
          'mdi mdi-exclamation',
          'mdi mdi-exclamation-thick',
          'mdi mdi-clipboard-alert-outline',
          'mdi mdi-sticker-alert-outline',
          'mdi mdi-coffee-outline',
        ]
      },
      {
        label: this._t('label_Traffic'),
        icons: [
          'mdi mdi-bus-alert',
          'mdi mdi-subway-alert-variant',
          'mdi mdi-traffic-light',
        ]
      },
      {
        label: this._t('label_Technical'),
        icons: [
          'mdi mdi-coolant-temperature',
          'mdi mdi-radioactive',
          'mdi mdi-printer-3d-nozzle-alert-outline',
          'mdi mdi-tray-alert',
          'mdi mdi-beaker-alert-outline',
          'mdi mdi-water-percent-alert',
          'mdi mdi-thermometer-alert',
          'mdi mdi-thermometer-lines',
          'mdi mdi-oil-level',
          'mdi mdi-dishwasher-alert',
          'mdi mdi-battery-alert-variant-outline',
          'mdi mdi-vibrate',
          'mdi mdi-watch-vibrate',
          'mdi mdi-fuse-alert',
          'mdi mdi-engine-outline',
          'mdi mdi-fridge-alert-outline',
          'mdi mdi-state-machine',
          'mdi mdi-gas-cylinder',
          'mdi mdi-diving-scuba-tank',
          'mdi mdi-fan-alert',
          'mdi mdi-lightbulb-on-outline',
          'mdi mdi-power-plug-off-outline',
          'mdi mdi-car-tire-alert',
          'mdi mdi-lightning-bolt-outline',
          'mdi mdi-transmission-tower',
          'mdi mdi-scale-balance',
        ]
      },
      {
        label: this._t('label_Weather'),
        icons: [
          'mdi mdi-snowflake-alert',
          'mdi mdi-snowflake-melt',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-weather-lightning',
          'mdi mdi-weather-pouring',
        ]
      },
      {
        label: this._t('label_media'),
        icons: [
          'mdi mdi-book-open-variant',
          'mdi mdi-cellphone-basic',
          'mdi mdi-cellphone',
          'mdi mdi-monitor',
          'mdi mdi-alarm-light-outline',
          'mdi mdi-phone-in-talk',
          'mdi mdi-email',
          'mdi mdi-card-text-outline',
          'mdi mdi-cellphone-text',
          'mdi mdi-message-text',
          'mdi mdi-cellphone-wireless',
          'mdi mdi-message-video',
          'mdi mdi-application-import',
          'mdi mdi-database-import',
          'mdi mdi-phone-voip',
          'mdi mdi-application-export',
          'mdi mdi-printer',
          'mdi mdi-database-export',
          'mdi mdi-motion-sensor',
          'mdi mdi-switch',
          'mdi mdi-serial-port',
          'mdi mdi-lighthouse',
          'mdi mdi-lighthouse',
          'mdi mdi-led-on',
          'mdi mdi-cup-water',
          'mdi mdi-gesture-tap-hold',
        ]
      }
    ]
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.srcOptions = [
      {value: 'EXIST', label: this._t('label_Selected_plan')},
      {value: 'NEW', label: this._t('label_Define_new_plan')},
      {value: 'SIMPLE', label: this._t('label_Simplified_entry')},
    ]
    this.getAlarmPoints()
    this.getAlarmRules()
    if (this.$route.params.id && this.$route.params.id.length > 0) {
      this.getAlarmPoint(this.$route.params.id)
    }

    this.checkOrgId()
  },
  methods: {
    ...mapActions({
      'callCalendarById': 'rotationalCalendar/callCalendarById',
      'createRotaionalCalendar': 'rotationalCalendar/callCreateCalendar',
      'updateRotaionalCalendar': 'rotationalCalendar/callUpdateCalendar',
      'callRotationalCalendars': 'rotationalCalendar/callCalendars',
    }),
    ...mapMutations({
      'setRotationalIsEditable': 'rotationalCalendar/setIsEditable',
      'createEmptyCalendar': 'rotationalCalendar/createEmptyCalendar',
    }),
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getAlarmPoints()
        }
      }, 100);
    },
    setOrgId(id) {
      this.orgId = id;
      this.getAlarmPoints()
    },
    getAlarmPoints() {
      getList('ALARM_TEMPLATE', 0, this.alarmPointSearch, 1000, this.orgId).then((response) => {
        this.alarmPoints = response.data.list
        this.alarmPoints.sort((a, b) => a.name.localeCompare(b.name))

        let groups = []

        this.alarmPoints.forEach(item => {
          let group = groups.find((g) => g.label == item.name && g.orgId == item.orgId)
          if (!group) {
            group = {
              key: 'g' + groups.length,
              label: item.name,
              alarmPointId: item.alarmPointId,
              systemId: item.systemId,
              selectable: false,
              children: [],
              type: 'group',
              orgId: item.orgId
            }
            groups.push(group)
          }
          group.children.push({
            key: item.alarmPointId,
            label: item.alarmPointId,
            alarmPointId: item.alarmPointId,
            systemId: item.systemId,
            selectable: true,
            type: 'alarm',
            data: item,
            orgId: item.orgId
          })
        }, this)

        this.alarmPointsGrp = groups.map((item) => {
          if (item.children.length < 2) {
            item = item.children[0]
            item.label = item.data.name
            item.alarmPointId = item.data.alarmPointId
            item.systemId = item.data.systemId
          }
          return item
        }, this)

        this.loading = false
      })
    },

    selectAlarmPoint(node) {
      this.getAlarmPlans()
      this.loadInstructions()
      if (!node || !node.data || !node.data.id) {
        return
      }
      this.getAlarmPoint(node.data.id)
    },
    getAlarmPoint(id) {
      if (id === '_') {
        this.selectedAlarmPoint = {
          id: null,
          icon: {
            name: '',
            color: ''
          }
        };
        return;
      }
      getItemById(id).then((response) => {
        this.isEditable = false
        this.selectedAlarmPoint = response.data
        if (this.selectedAlarmPoint.repeatMonInterval && this.selectedAlarmPoint.repeatMonInterval !== 0) {
          this.repeatableMonitored = true
        } else {
          this.repeatableMonitored = false
        }

        if (this.selectedAlarmPoint.alarmPlanId) {
          this.getAlarmPlan(this.selectedAlarmPoint.alarmPlanId)
        }
        this.callCalendarById(this.selectedAlarmPoint.rotationalCalendarId)
        this.callRotationalCalendars(this.orgId);
        this.rotationalCalendar.id = null;
        try {
          this.selectedAlarmPoint.icon = JSON.parse(this.selectedAlarmPoint.icon)
        } finally {
          if (!this.selectedAlarmPoint.icon || typeof this.selectedAlarmPoint.icon !== 'object') {
            this.selectedAlarmPoint.icon = {
              color: '#989898',
              name: 'mdi mdi-alert-outline'
            }
          }
        }
        this.srcOption = 'EXIST'
        this.$forceUpdate()
        this.loading = false
      })
    },
    deleteAlarmPoint() {
      removeItemById(this.selectedAlarmPoint.id).then(() => {
        this.selectedAlarmPoint = {
          id: null,
          icon: {
            color: '',
            name: ''
          }
        }
        this.selectedAlarmPointItem = {}
        this.deleteAlarmPointDialog = false
        this.getAlarmPoints()
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
    },
    copyTextVar() {
      this.selectedAlarmPoint.text = (this.selectedAlarmPoint.text ?? '') + this.selectedTextVar
      this.selectedTextVar = ''
    },
    syncSrcOptions() {
      switch (this.srcOption) {
        case 'EXIST':
          break
        case 'NEW':
          this.createAlarmPlan()
          break
        case 'SIMPLE':
          this.createAlarmPlan(true)
          this.activeTabSimple = 0
          break
      }
    },
    syncRepeatableMonitored() {
      if (this.repeatableMonitored) {
        this.selectedAlarmPoint.countRepeat = 0
        this.selectedAlarmPoint.repeatBetween = 0
      }
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
    validateAlarmPoint() {
      let result = true
      if (!this.selectedAlarmPoint.name || this.selectedAlarmPoint.name.length === 0) {
        this.validation.name = true
        result = false;
      } else {
        this.validation.name = false
      }
      if (!this.selectedAlarmPoint.text || this.selectedAlarmPoint.text.length === 0) {
        this.validation.text = true
        result = false;
      } else {
        this.validation.text = false
      }

      return result
    },
    initNewAlarmPointDialog() {
      this.getAlarmPlans()
      this.loadInstructions()
      prepare(this.orgId)
          .then(({data, status}) => {
            if (status === 200) {
              data.id = '_';
              data.icon = {
                color: '#3996f3',
                icon: '',
                name: 'mdi mdi-alert-outline'
              }
              this.selectedAlarmPoint = data;
              this.selectedAlarmPoint.repeatMonInterval = 1
              this.callCalendarById(this.selectedAlarmPoint.rotationalCalendarId);
              this.isEditable = true
              this.createAlarmPlan()
            }
          });
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }
    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    getAlarmPlan(id) {
      getAlarmPlan(id).then((response) => {
        this.selectedAlarmPlan = response.data
        this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
          if (callGroup.isRandom == true && callGroup.isEscalation == false) {
            callGroup.memberSelectRule = 'RANDOM'
          } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
            callGroup.memberSelectRule = 'ESCALATION'
          } else {
            callGroup.memberSelectRule = 'NONE'
          }
          this.syncGroupMemberCallRule(callGroup)
        }, this)
      })
    },
    getAlarmPlans() {
      getList('ALARMPLAN', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmPlans = response.data.list
        this.alarmPlans.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    getAlarmRules() {
      getList('ALARM_RULE', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmRules = response.data.list
        this.alarmRules.sort((a, b) => a.name.localeCompare(b.name))
        // this.currentPage = response.data.currentPage
        // this.totalRecords = response.data.count
        this.loading = false
      })
    },
    saveAlarm() {
      if (this.srcOption == 'EXIST') {
        this.saveAlarmPoint()
      } else {
        if (this.selectedAlarmPlan !== null && this.selectedAlarmPlan.callGroups && this.selectedAlarmPlan.callGroups.length > 0) {
          this.saveAlarmPlan()
        } else {
          this.$root.showMessage('Please, select alarm participants.', 'error')
        }
      }
      this.updateRotaionalCalendar();
    },
    saveAlarmPlan() {
      if (!this.validateAlarmPoint('selectedAlarmPoint')) {
        return
      }
      let data = Object.assign({}, this.selectedAlarmPlan)
      if (data !== null && data.callGroups && data.callGroups.length > 0) {
        data.callGroups.forEach(function (group) {
          if (group.id && group.id.startsWith('_')) {
            delete group.id
          }
          if (group.alarmPlanId == null) {
            delete group.alarmPlanId
          }

          delete group.memberSelectRule
          delete group.memberCallRuleSimple

          group.members.forEach(function (member) {
            if (member.id && member.id.startsWith('_')) {
              delete member.id
            }
          })
        })

        if (data.id != null && !data.id.startsWith('_')) {
          updateAlarmPlan(data).then((response) => {
            this.getAlarmPlan(response.data.id)

            this.saveAlarmPoint()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        } else {
          delete data.id
          createAlarmPlan(data).then((response) => {
            this.getAlarmPlan(response.data.id)
            this.selectedAlarmPoint.alarmPlanId = response.data.id

            this.saveAlarmPoint()
            this.getAlarmPlans()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        }
        this.activeTabSimple = 0
        this.isEditable = false
      }

    },
    saveAlarmPoint() {
      if (!this.validateAlarmPoint('selectedAlarmPoint')) {
        return
      }
      if (this.alarmPointError.length > 0) {
        return false;
      }
      this.selectedAlarmPoint.icon = JSON.stringify(this.selectedAlarmPoint.icon)
      if (!this.selectedAlarmPoint.id || this.selectedAlarmPoint.id == null || this.selectedAlarmPoint.id.startsWith('_')) {
        delete this.selectedAlarmPoint.id

        create(this.selectedAlarmPoint).then((response) => {
          this.getAlarmPoint(response.data.id)
          this.isEditable = false
          this.getAlarmPoints()
        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      } else {
        this.$refs.orgLink.moveToOrg((increaseVersion) => {
          if (increaseVersion) this.selectedAlarmPoint.version++
          update(this.selectedAlarmPoint).then((response) => {
            this.getAlarmPoint(response.data.id)
            this.isEditable = false
            this.getAlarmPoints()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        })
      }
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 0
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    hasParticipantGroups(callGroup) {
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    createAlarmPlan(simple = false) {
      this.selectedAlarmPlan = {
        id: null,
        name: simple ? 'Participant plan for ' + this.selectedAlarmPoint.alarmPointId : '',
        description: simple ? 'Automatically generated participant plan for ' + this.selectedAlarmPoint.name : '',
        orgId: this.selectedAlarmPoint.orgId,
        orgName: this.selectedAlarmPoint.orgName,
        callGroups: []
      }

      this.newCallGroup.name = this._t('label_default')
      this.createCallGroup()
    },
    toMember(participantId, participantGroupId) {
      if (this.isEditable) {
        return
      }

      if (participantId) {
        router.push(`/participants/${participantId}`)
        return
      }

      if (participantGroupId) {
        router.push(`/participant-groups/${participantGroupId}`)

      }
    },
    addInstruction() {
      if (!this.selectedAlarmPoint.instructionFiles) {
        this.selectedAlarmPoint.instructionFiles = [];
      }
      if (this.selectedAlarmPoint.instructionFiles.indexOf(this.selectedAlarmPoint.instructionFile) === -1) {
        this.selectedAlarmPoint.instructionFiles.push(this.selectedAlarmPoint.instructionFile);
      }
      this.selectedAlarmPoint.instructionFile = null;
    },
    downloadInstruction() {
      downloadInstruction(this.selectedAlarmPoint.instructionFile)
    },
    deleteIntruction(instruction) {
      this.selectedAlarmPoint.instructionFiles
          .splice(this.selectedAlarmPoint.instructionFiles.findIndex(i => i === instruction), 1)
    },
    onAssignRotationalCalendar() {
      this.selectedAlarmPoint.rotationalCalendarId = this.rotationalCalendar.id ?? null;
      this.saveAlarmPoint();
    },
    onUnAssignRotationalCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.selectedAlarmPoint.rotationalCalendarId = null;
          this.saveAlarmPoint();
        },
        reject: () => {
        }
      });
    },
    onCreateRotationalCalendar() {
      this.createEmptyCalendar({orgId: this.orgId});
      this.getCalendar.name = this.rotationalCalendar.name;
      this.getCalendar.description = this.rotationalCalendar.description;
      this.getCalendar.validFrom = this.rotationalCalendar.validFrom;
      this.createRotaionalCalendar()
          .then(() => {
            if (this.getCalendar.id) {
              this.selectedAlarmPoint.rotationalCalendarId = this.getCalendar.id ?? null;
              this.saveAlarmPoint();
            }
          });
    },
  },
  computed: {
    ...mapGetters({
      'getRotationalCalendars': 'rotationalCalendar/getCalendars',
      'getCalendar': 'rotationalCalendar/getCalendar',
    }),
  },
}
</script>

<style scoped>
:deep(.alarm-point-hidden) {
  display: none;
}
</style>
